import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice:`}</p>
    <p>{`Skin the cat & Toes thru Rings`}</p>
    <p>{`then,`}</p>
    <p>{`“Mini Murph”`}</p>
    <p>{`2 Lap Run`}</p>
    <p>{`10 rounds of`}</p>
    <p>{`5-Pullups`}</p>
    <p>{`10-Pushups`}</p>
    <p>{`15-Squats`}</p>
    <p>{`2 Lap Run`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`All members and their families are invited to watch the air show
and Thunder at The Ville this Saturday!  We’ll have a potluck, play some
games, and hang out.  We’ll start around 2:00pm but we’ll be open all
day until Thunder is over.  Hope you can make it!  We’ll have our normal
class times on Saturday as well.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      